<template>
  <div>
    <my-nav-bar
      title="添加商品"
      left-text="返回"
      left-arrow
    />
    <div class="filter">
      <van-search v-model="listQuery.name" placeholder="请输入商品名称" clearable @search="handleSearch" />
    </div>
    <van-list
      ref="list"
      v-model="loading"
      class="list"
      :finished="finished"
      @load="getList"
    >
      <div v-for="goods in list" :key="goods.id" class="goods" @click="handleAddGoods(goods)">
        <div class="image">
          <van-image :src="goods.thumb_image" width="2.13333rem" height="2.13333rem" />
        </div>
        <div class="info">
          <div>{{ goods.name }}</div>
          <div class="size">规格：1 * {{ goods.size }}</div>
          <div class="price">单价：￥{{ goods.price }}</div>
        </div>
      </div>
    </van-list>
    <my-dialog v-model="showInputDialog" title="商品数量" @confirm="handleAddGoodsConfirm">
      <van-field v-if="handleGoods.large_unit" v-model="largeQuantity" label-width="60px" :label="`${handleGoods.large_unit}数`" :placeholder="`请输入${handleGoods.large_unit}数`" clearable type="number" />
      <van-field v-model="leastQuantity" :label="`${handleGoods.least_unit}数`" label-width="60px" :placeholder="`请输入${handleGoods.least_unit}数`" clearable type="number" />
    </my-dialog>
  </div>
</template>

<script>
import myNavBar from '@/components/my-nav-bar'
import { getGoodsList } from '@/api/salesman-order'
import MyDialog from '@/components/my-dialog'
export default {
  components: { myNavBar, MyDialog },
  data() {
    return {
      listQuery: {
        page: 0,
        name: '',
        client_id: this.$route.query.client_id
      },
      list: [],
      loading: false,
      finished: false,
      handleGoods: {},
      showInputDialog: false,
      largeQuantity: '',
      leastQuantity: ''
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.listQuery.page++
      getGoodsList(this.listQuery).then(res => {
        this.list = this.list.concat(res.data.list)
        this.loading = false
        this.finished = res.data.list.length < 10
      })
    },
    handleSearch() {
      this.listQuery.page = 0
      this.list = []
      this.finished = false
      this.$refs.list.check()
    },
    handleAddGoods(goods) {
      this.handleGoods = Object.assign({}, goods)
      this.showInputDialog = true
    },
    handleAddGoodsConfirm() {
      if (!this.largeQuantity && !this.leastQuantity) {
        this.fail('请输入商品数量')
        return
      }
      this.handleGoods.large_quantity = this.largeQuantity
      this.handleGoods.least_quantity = this.leastQuantity
      this.$eventBus.$emit('add-order-goods', this.handleGoods)
      this.success('添加成功')
    }
  }
}
</script>

<style lang="scss" scoped>
  .filter {
    position: fixed;
    left: 0;
    top: 46px;
    right: 0;
    z-index: 9;
  }
  .list {
    padding: 110px 10px 0 10px;
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .goods {
    background-color: #fff;
    display: flex;
    margin-bottom: 10px;
    padding: 10px;
  }
  .image {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .size {
    font-size: 12px;
    color: #989898;
  }
  @import '@/styles/color.scss';
  .price {
    font-size: 12px;
    color: $amountRedColor;
  }
</style>
